<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="pa-5" flat>
          <div class="d-flex">
            <v-avatar size="60">
              <v-img
                :lazy-src="user_data['image'] ? user_data['image'] : avatar"
                :src="user_data['image'] ? user_data['image'] : avatar"
              ></v-img>
            </v-avatar>
            <v-card-title class="py-0 mb-n10">
              {{ user_data["name"] }}
            </v-card-title>
          </div>
          <v-divider class="mt-1 primary"></v-divider>
          <v-card-title class="pb-0">
            Email: <small class="pl-2 mb-n1">{{ user_data.email }}</small>
          </v-card-title>
          <v-card-title class="py-0">
            Account Type:
            <small class="pl-2 capitalize mb-n1">
              {{ user_data.account_type }}
            </small>
          </v-card-title>
          <v-card-title class="py-0">
            Account Status:
            <small
              :class="
                user_data.status === 'suspended'
                  ? 'error--text pl-2 capitalize mb-n1'
                  : 'pl-2 capitalize mb-n1'
              "
            >
              {{ user_data.status }}
            </small>
          </v-card-title>
          <v-card-title class="py-0">
            Partnered:
            <small class="pl-2 capitalize mb-n1">
              {{ user_data.partnered }}
            </small>
          </v-card-title>
          <v-card-title v-if="user_data.partner.length" class="py-0">
            Partner Name:
            <small class="pl-2 capitalize mb-n1">
              {{ user_data.partner[0].name }}
            </small>
          </v-card-title>
          <v-card-title class="py-0">
            Sub start date:
            <small class="pl-2 mb-n1">
              {{
                subscription
                  ? new Date(subscription["start_date"]).toDateString()
                  : "Not subscribed"
              }}
            </small>
          </v-card-title>
          <v-card-title class="py-0">
            Sub end date:
            <small class="pl-2 mb-n1">
              {{
                subscription
                  ? new Date(subscription["end_date"]).toDateString()
                  : "Not subscribed"
              }}
            </small>
          </v-card-title>
          <v-card-title class="py-0">
            Minutes left:
            <small class="pl-2 mb-n1">
              {{ user_data["minutes_left"] || 0 }}
            </small>
          </v-card-title>
          <v-card-title class="py-0">
            Total Recordings
            <small class="pl-2 mb-n1">
              {{ user_data.recordings.length }}
            </small>
          </v-card-title>
          <v-card-title class="py-0">
            Total Received:
            <small class="pl-2 mb-n1">
              {{ user_data.received.length }}
            </small>
          </v-card-title>
          <v-card-title class="py-0">
            Total Requested Links:
            <small class="pl-2 mb-n1">
              {{ user_data.pending.length }}
            </small>
          </v-card-title>
          <v-form lazy-validation ref="form" class="d-flex ml-4 mt-2">
            <!--suppress JSDeprecatedSymbols -->
            <v-text-field
              class="mr-2"
              rounded
              v-model="form.minutes"
              dense
              outlined
              background-color="secondary"
              placeholder="Add minutes"
              :rules="[(v) => !!v || 'Please enter minutes']"
              onkeypress="return /[0-9]/i.test(event.key)"
            ></v-text-field>
            <v-btn
              @click="$refs.form.validate() ? addMinutes() : null"
              class="primary capitalize"
              depressed
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-form>
          <v-form lazy-validation ref="passwordForm" class="d-flex ml-4 mt-2">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-textarea
                  class="mr-2"
                  rounded
                  v-model="form.masked_password"
                  dense
                  outlined
                  background-color="secondary"
                  placeholder="Paste encrypted password"
                  :rules="[(v) => !!v || 'Please enter minutes']"
                  rows="3"
                ></v-textarea>
              </v-col>
              <v-col class="py-0 mt-n3">
                <v-btn
                  @click="
                    $refs.passwordForm.validate() ? updatePassword() : null
                  "
                  class="error capitalize"
                  block
                  depressed
                  :loading="passwordBtnLoading"
                >
                  Change pasword
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="py-2" flat>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(tab, i) in tabs" :key="i">
              {{ tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="tab in tabs" :key="tab">
              <v-data-table
                :items="
                  tab === 'My Recordings'
                    ? recordings
                    : tab === 'Received Recordings'
                    ? received
                    : tab === 'Requested Links'
                    ? pending
                    : []
                "
                :headers="
                  tab === 'My Recordings'
                    ? headers
                    : tab === 'Received Recordings'
                    ? headers
                    : requestedHeader
                "
                :items-per-page="5"
              >
                <template v-slot:item.numbers="{ index }">
                  <div>{{ index + 1 }}</div>
                </template>
                <template v-slot:item.title="{ item }">
                  <div>
                    <a
                      href="javascript:void(0)"
                      class="font-weight-bold text-decoration-none"
                      @click="viewDetails(item)"
                    >
                      {{ item.title }}
                    </a>
                  </div>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-icon @click="deleteItem(item, tab)" class="error--text">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div>
                    <v-icon
                      @click="copyLink(item.id)"
                      color="teal"
                      class="rounded-xl pa-1 ml-2 grey lighten-3"
                      title="copy"
                    >
                      mdi-link-variant
                    </v-icon>
                    <v-text-field
                      style="opacity: 0; position: absolute"
                      :value="item.record_link"
                      :id="item.id"
                    ></v-text-field>
                  </div>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" bottom right color="success darken-2">
      {{ success_msg }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "UserProfile",
  data() {
    return {
      avatar: require("../assets/avatar.png"),
      tab: null,
      tabs: ["My Recordings", "Received Recordings", "Requested Links"],
      headers: [
        { text: "#", value: "numbers", orderable: false, sortable: false },
        { text: "Name", value: "title" },
        { text: "Recorded By", value: "user.name" },
        { text: "category", value: "category.name" },
        { text: "Recorded", value: "created_at" },
        { text: "Action", value: "action" },
      ],
      requestedHeader: [
        { text: "#", value: "numbers", orderable: false, sortable: false },
        { text: "Label", value: "name" },
        { text: "Link", value: "record_link" },
        { text: "Number of Recordings", value: "recordings_count" },
        { text: "Action", value: "actions" },
      ],
      form: {},
      loading: false,
      success_msg: "",
      snackbar: false,
      passwordBtnLoading: false,
    };
  },
  computed: {
    ...mapGetters("users", ["user_data"]),
    subscription() {
      return this.user_data.payments.at(-1);
    },
    recordings() {
      return this.user_data.recordings;
    },
    received() {
      return this.user_data.received;
    },
    pending() {
      return this.user_data.pending;
    },
  },
  methods: {
    ...mapActions("recordings", ["delete_recording"]),
    ...mapActions("users", ["add_minutes", "update_password"]),
    ...mapMutations({
      LOADING: "auth/LOADING",
      SHOW_DETAILS: "recordings/SHOW_DETAILS",
    }),
    async deleteItem(item, tab) {
      let confirmDelete = confirm("Are you sure you want to delete this item?");
      if (confirmDelete) {
        let records =
          tab === "My Recordings"
            ? this.recordings
            : tab === "Received Recordings"
            ? this.received
            : tab === "Requested Links"
            ? this.pending
            : [];
        let record = records.findIndex((x) => x.id === item.id);
        this.LOADING(true);
        try {
          const response = await this.delete_recording(item);
          records.splice(record, 1);
          this.LOADING(false);
          this.$response.sendSuccess(response);
        } catch (e) {
          this.$response.sendError(e);
        }
      }
    },
    async addMinutes() {
      this.form.user_id = this.user_data.id;
      this.loading = true;
      try {
        const response = await this.add_minutes(this.form);
        this.user_data.minutes_left = response.data.minutes_left;
        this.loading = false;
        this.$response.sendSuccess(response);
      } catch (e) {
        this.loading = false;
        this.$response.sendError(e);
      }
    },
    copyLink(id) {
      let link = document.getElementById(id);
      link.select();
      document.execCommand("copy");
      this.success_msg = "Link copied to clipboard";
      this.snackbar = true;
    },
    viewDetails(item) {
      this.SHOW_DETAILS(item);
      this.$router.push({
        name: "MyRecordingDetails",
        params: { id: item.id },
      });
    },
    async updatePassword() {
      this.passwordBtnLoading = true;
      this.form.user_id = this.user_data.id;
      try {
        const response = await this.update_password(this.form);
        this.$response.sendSuccess(response);
        this.passwordBtnLoading = false;
      } catch (e) {
        this.passwordBtnLoading = false;
        this.$response.sendError(e);
      }
    },
  },
  created() {
    this.form.masked_password = this.user_data.password;
  },
};
</script>

<style scoped></style>
